import ContactForm from "@litonarefin/components/ContactForm";

function JltContactForm() {
    return (
        <div className="jlt-col-span-12 lg:jlt-col-span-8 jlt-bg-[#FFFFFF] jlt-rounded-lg jlt-py-8 jlt-px-6 md:jlt-py-12 md:jlt-px-8">
            <ContactForm />
        </div>
    );
}

export default JltContactForm;
