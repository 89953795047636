"use client";

import React, { useState } from "react";
import axios from "axios";
import Loader from "@litonarefin/components/Loader";
import { validateEmailCheck } from "@litonarefin/utils/validateEmailCheck";
import { handleToastr } from "@litonarefin/utils/handleToastr";

function ContactForm() {
    const [spinner, setSpinner] = useState(false);
    const [formInfo, setFormInfo] = useState({
        names: {
            first_name: "",
            last_name: "",
        },
        email: "",
        subject: "",
        website: "",
        message: "",
        site_source: process.env.NEXT_PUBLIC_SOURCE_SITE,
    });
    const [emptyFormInfoCheck, setEmptyFormInfoCheck] = useState({});

    const handleOnChange = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        if (key == "first_name" || key == "last_name") {
            setFormInfo({
                ...formInfo,
                names: { ...formInfo.names, [key]: value },
            });
        } else {
            setFormInfo({ ...formInfo, [key]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // validation
        if (
            !formInfo?.names?.first_name ||
            !formInfo?.names?.last_name ||
            !formInfo?.email ||
            !validateEmailCheck(formInfo?.email) ||
            !formInfo?.message
        ) {
            setEmptyFormInfoCheck({
                ...emptyFormInfoCheck,
                names: {
                    first_name: formInfo?.names?.first_name ? false : true,
                    last_name: formInfo?.names?.last_name ? false : true,
                },
                email: formInfo?.email
                    ? !validateEmailCheck(formInfo?.email)
                        ? "invalid"
                        : false
                    : true,
                message: formInfo?.message ? false : true,
            });
            return;
        } else {
            setEmptyFormInfoCheck({
                ...emptyFormInfoCheck,
                names: {
                    first_name: false,
                    last_name: false,
                },
                email: false,
                message: false,
            });
        }

        setSpinner(true);

        let { data } = await axios.post(`/api/contact`, formInfo);

        if (data?.success) {
            setSpinner(false);
            handleToastr("Form Submitted Successfully!");
            setFormInfo({
                names: {
                    first_name: "",
                    last_name: "",
                },
                email: "",
                subject: "",
                website: "",
                message: "",
                site_source: process.env.NEXT_PUBLIC_SOURCE_SITE,
            });
        }
    };

    return (
        <form action="">
            <div className="jlt-mb-6 sm:jlt-grid sm:jlt-grid-cols-2 jlt-gap-5">
                <div>
                    <label
                        htmlFor="first_name"
                        className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        First Name *
                    </label>
                    <input
                        required
                        type="text"
                        id="first_name"
                        name="first_name"
                        onChange={handleOnChange}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                        value={formInfo.names.first_name}
                    />
                    {emptyFormInfoCheck?.names?.first_name ? (
                        <span className="jlt-text-red-400 jlt-text-xs">
                            First name is required.
                        </span>
                    ) : null}
                </div>
                <div className="jlt-mt-6 sm:jlt-mt-0">
                    <label
                        htmlFor="last_name"
                        className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                        Last Name *
                    </label>
                    <input
                        required
                        type="text"
                        id="last_name"
                        name="last_name"
                        onChange={handleOnChange}
                        className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                        value={formInfo.names.last_name}
                    />
                    {emptyFormInfoCheck?.names?.last_name ? (
                        <span className="jlt-text-red-400 jlt-text-xs">Last name is required.</span>
                    ) : null}
                </div>
            </div>
            <div className="jlt-mb-6">
                <label
                    htmlFor="email"
                    className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    Email *
                </label>
                <input
                    required
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleOnChange}
                    value={formInfo.email}
                    className="jlt-w-full jlt-h-[40px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                />
                {emptyFormInfoCheck?.email === true ? (
                    <span className="jlt-text-red-400 jlt-text-xs">Email is required.</span>
                ) : null}
                {emptyFormInfoCheck?.email === "invalid" ? (
                    <span className="jlt-text-red-400 jlt-text-xs">Enter a valid email.</span>
                ) : null}
            </div>
            <div className="jlt-mt-6">
                <label
                    htmlFor="subject"
                    className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal jlt-select-none">
                    Subject
                </label>
                <input
                    type="text"
                    id="subject"
                    name="subject"
                    onChange={handleOnChange}
                    value={formInfo.subject}
                    className="jlt-w-full jlt-h-10 jlt-border-[1px] jlt-border-[#EBEEF5] jlt-bg-[#FCFDFF] jlt-px-4 jlt-rounded-[2px] jlt-outline-0 jlt-text-base jlt-text-[#3F475A] jlt-font-normal jlt-mt-2"
                />
            </div>
            <div className="jlt-mt-6">
                <label
                    htmlFor="website"
                    className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal jlt-select-none">
                    Website URL
                </label>
                <input
                    id="website"
                    type="text"
                    name="website"
                    onChange={handleOnChange}
                    value={formInfo.website}
                    className="jlt-w-full jlt-h-10 jlt-border-[1px] jlt-border-[#EBEEF5] jlt-bg-[#FCFDFF] jlt-px-4 jlt-rounded-[2px] jlt-outline-0 jlt-text-base jlt-text-[#3F475A] jlt-font-normal jlt-mt-2"
                />
            </div>
            <div className="jlt-mt-6">
                <label
                    htmlFor="message"
                    className="jlt-block jlt-text-base jlt-text-[#3F475A] jlt-font-normal">
                    Message *
                </label>
                <textarea
                    required
                    id="message"
                    name="message"
                    onChange={handleOnChange}
                    value={formInfo.message}
                    className="jlt-w-full jlt-h-[164px] jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[2px] jlt-mt-2 jlt-px-5 jlt-py-3 jlt-outline-none jlt-text-base jlt-text-[#3F475A] jlt-font-normal"
                />
                {emptyFormInfoCheck?.message ? (
                    <span className="jlt-text-red-400 jlt-text-xs">Message is required.</span>
                ) : null}
            </div>
            <div className="submit-area jlt-flex jlt-gap-x-25">
                <button
                    disabled={spinner}
                    onClick={handleSubmit}
                    className="jlt-h-[44px] jlt-mt-6 jlt-py-3 jlt-px-6 jlt-bg-[#4CAF50] jlt-shadow-md jlt-shadow-[0px_0px_20px rgba(173, 173, 188, 0.15)] jlt-rounded-[25px] jlt-text-sm jlt-text-[#FFFFFF] jlt-font-bold">
                    Submit Query
                </button>
                {spinner && (
                    <div className="action-loader">
                        <Loader />
                    </div>
                )}
            </div>
        </form>
    );
}

export default ContactForm;
